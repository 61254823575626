/**
 * @file standalone/suppress_ecom.scss
 *
 * shared styles for the "suppress ecom for retailers" feature
 *
 */

body.is-retailer {
  .product_brief__button--add-to-bag,
  .spp-product__add-button,
  .product-full__add-button,
  .notify_me,
  .quickshop__add-button,
  .elc-add-to-bag-button,
  .gwp-fall__product-cta--add,
  .spp-product__mini-bag-add-button,
  .wishlist_add_to_bag,
  .add-to-bag,
  .add_to_bag_button_module,
  .add-to-cart,
  .promo_bca_button,
  .cart_cross_sell_item .add_button,
  .recommended-item .addtobag,
  .recommended-products-panel .add,
  .engraving-save,
  button[data-test-id='add-to-cart'] {
    visibility: hidden;
  }
  .ff-results__product {
    .sku-brief-editorial {
      .sku-brief-editorial__button-wrapper {
        .button--dark-secondary {
          visibility: hidden;
        }
      }
    }
  }
}
